import React from 'react'

// Mobile Containers
const mobileSectionContainer =
  'relative z-10 mx-6 my-12 rounded-xxlg md:m-auto bg-gp-white shadow-uniform md:bg-transparent md:shadow-none'

const mobileSectionContainerHighlight = `${mobileSectionContainer} border-4 md:border-0  border-gp-violet`

export default function MobileContainer({ highlight, children }) {
  return (
    <>
      <div
        className={`mobileSectionContainer ${
          highlight ? mobileSectionContainerHighlight : mobileSectionContainer
        }`}
      >
        {children}
      </div>
    </>
  )
}
