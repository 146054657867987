import React from 'react'
// Condition icons
import sleepCondition from '../../images/home/conditions/condition-sleep-new.svg'
import digestiveCondition from '../../images/home/conditions/condition-digestive-new.svg'
import covidCondition from '../../images/home/conditions/condition-long-covid-new.svg'
import mentalCondition from '../../images/home/conditions/condition-mental-health-new.svg'
import mskCondition from '../../images/home/conditions/condition-msk-new.svg'
import cancerSurvivorshipCondition from '../../images/home/conditions/condition-cancer-survivorship.svg'

export default function ConditionList() {
  // Conditions
  const conditionCard =
    'flex flex-col items-center md:items-middle justify-start w-full md:mb-6 rounded-xlg'

  const conditionTitle =
    'w-10/12 text-center font-bold uppercase text-lg lg:text-xl py-4 -mt-6 mb-2 rounded-t-xlg hidden md:block'

  const conditions = [
    {
      class: 'bg-white md:bg-gp-violet-5',
      classTitle: 'text-white bg-gp-violet-70',
      name: 'Musculoskeletal',
      icon: mskCondition,
      iconAlt: 'MSK condition icon.',
      features: [
        {
          name: 'Back pain'
        },
        {
          name: 'Neck pain'
        },
        {
          name: 'Knee pain'
        },
        {
          name: 'Hip pain'
        },
        {
          name: 'Shoulder pain'
        }
      ]
    },
    {
      class: 'bg-white md:bg-gp-blue-10',
      classTitle: 'text-white bg-gp-blue',
      name: 'Long COVID',
      icon: covidCondition,
      iconAlt: 'Long COVID condition icon.',
      features: [
        {
          name: 'Fatigue & insomnia'
        },
        {
          name: 'Brain fog'
        },
        {
          name: 'Sensory changes'
        },
        {
          name: 'Trouble breathing'
        },
        {
          name: 'Aches & pains'
        },
        {
          name: 'Digestive issues'
        }
      ]
    },
    {
      class: 'bg-white md:bg-gp-honey-10',
      classTitle: 'text-gp-grey-90 bg-gp-honey',
      name: 'Sleep',
      icon: sleepCondition,
      iconAlt: 'Sleep condition icon.',
      features: [
        {
          name: 'Insomnia'
        },
        {
          name: 'Circadian rhythm disorders'
        },
        {
          name: 'Obstructive sleep apnea'
        },
        {
          name: 'Sleep-related movement disorders'
        }
      ]
    },
    {
      class: 'bg-white md:bg-gp-grapefruit-10',
      classTitle: 'text-white bg-gp-grapefruit',
      name: 'Digestive',
      icon: digestiveCondition,
      iconAlt: 'Digestive health condition icon',
      features: [
        {
          name: 'IBS-C'
        },
        {
          name: 'IBS-D'
        },
        {
          name: 'GERD'
        },
        {
          name: 'Functional abdominal bloating'
        },
        {
          name: 'Belching disorders'
        }
      ]
    },
    {
      class: 'bg-white md:bg-gp-slate-10 col-span-2 md:col-span-1',
      classTitle: 'text-white bg-gp-slate',
      name: 'Mental Health',
      icon: mentalCondition,
      iconAlt: 'Mental health condition icon',
      features: [
        {
          name: 'Burnout'
        },
        {
          name: 'Financial stress'
        },
        {
          name: 'Relationship issues'
        },
        {
          name: 'General stress'
        },
        {
          name: 'Anxiety'
        },
        {
          name: 'Depression'
        }
      ]
    },
    {
      class: 'bg-white md:bg-gp-magnolia col-span-2 md:col-span-1',
      classTitle: 'text-white bg-gp-manatee pt-1 pb-2',
      name: 'Cancer Survivorship',
      icon: cancerSurvivorshipCondition,
      iconAlt: 'Cancer survivorship condition icon',
      features: [
        {
          name: 'Fear of recurrence'
        },
        {
          name: 'Anxiety and depression'
        },
        {
          name: 'Insomnia and fatigue'
        },
        {
          name: 'Nausea and diarrhea'
        },
        {
          name: 'Weight gain or loss'
        },
        {
          name: 'Cognitive concerns'
        },
        {
          name: 'Sexuality concerns'
        }
      ]
    }
  ]

  return (
    <>
      <div className="grid grid-cols-2 gap-6 md:grid-cols-3">
        {conditions.map((condition) => (
          <div
            key={condition.name}
            className={`${conditionCard} ${condition.class}`}
          >
            <h3 className={`${conditionTitle} ${condition.classTitle}`}>
              {condition.name}
            </h3>

            {condition.features.map((feature) => (
              <div key={feature.name} className="hidden md:block text-center">
                <p className="my-1 px-6 text-lg leading-snug text-gp-grey-90">
                  {feature.name}
                </p>
              </div>
            ))}
            <div className="flex items-center justify-center w-16 h-16 sm:h-20 sm:w-20 mt-auto">
              <img
                src={condition.icon}
                alt={condition.iconAlt}
                className="w-16 h-16"
              />
            </div>
            <div className="block md:hidden text-sm">{condition.name}</div>
          </div>
        ))}
      </div>
    </>
  )
}
