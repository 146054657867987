import React from 'react'
import CarouselContainer from '../Carousel/CarouselContainer'
import styled from 'styled-components'

const Slide = styled.div`
  display: flex !important;
`

const TestimonialSlider = () => {
  return (
    <div className="max-w-5xl mx-auto slick-scope">
      <CarouselContainer sliderSettings={sliderSettings}>
        {testimonialSlides.map((e) => (
          <Slide key={e.quote}>
            <div
              className={`flex flex-col max-w-md p-6 my-6 md:my-12 bg-gp-${e.backgroundColor} rounded-xlg border-2 border-gp-violet`}
            >
              <div className="flex flex-col justify-center h-full p-4 space-y-4 ">
                <p className="text-lg leading-normal text-gp-violet">
                  &ldquo;{e.quote}&rdquo;
                </p>

                <div className="flex items-center justify-start space-x-2">
                  <span
                    className={`bg-gp-${e.backgroundColor} flex items-center justify-center w-12 h-12 text-2xl font-bold leading-none rounded-full  text-gp-grey-90 border border-gp-grey-60`}
                  >
                    <span className="-mt-1">{e.initial}</span>
                  </span>
                  <p className="text-base lg:text-xl text-gp-violet">
                    {e.firstName}
                  </p>
                </div>
              </div>
            </div>
          </Slide>
        ))}
      </CarouselContainer>
    </div>
  )
}

const testimonialSlides = [
  {
    quote:
      'I just did a hiking vacation and hiked 10 miles in one day. I want to thank Goodpath for changing my life!',
    firstName: 'Ameet',
    initial: 'A',
    backgroundColor: 'grapefruit-30'
  },
  {
    quote: "After 20 years of insomnia, I'm no longer afraid to go to sleep.",
    firstName: 'Melanie',
    initial: 'M',
    backgroundColor: 'honey-30'
  },
  {
    quote:
      'I have a new favorite evening routine, thanks you all. I get ready for sleep - and then I actually sleep. And the diet advice means I lost weight too!',
    firstName: 'Danielle',
    initial: 'D',
    backgroundColor: 'slate-30'
  },
  {
    quote:
      "This was so much more than I could have hoped! My diet, my stress, it's all better!",
    firstName: 'Anthony',
    initial: 'A',
    backgroundColor: 'grapefruit-30'
  },
  {
    quote:
      'I finally felt heard, and that my wellbeing and health were the number one priority. I started feeling better even in that first week.',
    firstName: 'June',
    initial: 'J',
    backgroundColor: 'honey-30'
  },
  {
    quote: 'I want to thank you for changing my life.',
    firstName: 'Vicente',
    initial: 'V',
    backgroundColor: 'slate-30'
  },
  {
    quote:
      'My coach was such a big part. She kept me motivated and pointing out my good work, and helped me focus on how I was already feeling better.',
    firstName: 'Carmen',
    initial: 'C',
    backgroundColor: 'grapefruit-30'
  }
]

const sliderSettings = {
  class: 'flex items-stretch',
  arrows: false,
  autoplay: true,
  autoplaySpeed: 12000,
  speed: 800,
  dots: false,
  centerMode: true,
  infinite: true,
  pauseOnHover: true,
  slidesToScroll: 1,
  slidesToShow: 2,
  responsive: [
    {
      breakpoint: 600,
      settings: {
        autoplay: true,
        autoplaySpeed: 12000,
        speed: 800,
        centerMode: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: false
      }
    }
  ]
}

export default TestimonialSlider
