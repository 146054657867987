import { Link } from 'gatsby'
import React from 'react'
import EmbedVideo from '../components/common/EmbedVideo'
import homepageHero from '../images/home/heroes/woman-goodpath-hero-callouts.svg'
import Circle from '../../assets/geometry/circle.svg'

// Integrative Animation
import integrativeGifMobile from '../images/home/integrative-care-mobile.gif'
import integrativeGifDesktop from '../images/home/integrative-care-desktop.gif'

// For articles
import Time from '../images/press/logos/time-logo.png'
import Bloomberg from '../images/press/logos/bloomberg-logo.png'
import yahooLogo from '../images/press/logos/yahoo-finance-logo.png'

// Components
import TestimonialSlider from '../components/Homepage/TestimonialSlider'
import SEO from '../components/SEO'
import SectionContainer from '../components/Homepage/SectionContainer'
import Stat from '../components/Homepage/Stat'
import PartnerImages from '../components/Homepage/PartnerImages'
import ConditionList from '../components/Homepage/ConditionList'
import MobileContainer from '../components/Homepage/MobileContainer'

// Styles
// Typography
const paragraphStyle = 'text-lg leading-normal text-gp-violet md:text-xl'
const h2 = 'text-2xl lg:text-3xl leading-tight'
const h2Violet = `${h2} text-gp-violet text-center`

// Cards
const articleHeadline = 'text-2xl font-bold leading-snug text-gp-violet mt-2'
const articleDate = 'mt-2 text-lg font-normal text-gp-violet'

const Homepage = ({ location }) => {
  return (
    <>
      <SEO
        title="Integrative, whole-person care for chronic conditions"
        description="The cure for point solution fatigue addressing MSK (Musculoskeletal), Sleep, Mental Health, Long COVID, and Digestive Health in one platform. Heal the conditions that matter most to employees."
        location={location}
      />
      {/* hero */}
      <main className="relative w-full h-full">
        <div className="relative z-10 bg-gp-white">
          <div className="">
            <SectionContainer border="border-bottom" size="5xl">
              <div className="relative z-10 pt-4 pl-6 pr-6 mb-8 lg:pr-0 lg:pl-0 md:mb-0">
                <div className="grid gap-8 md:grid-cols-2">
                  <div className="flex flex-col items-start justify-center mt-2 md:space-y-6 md:mt-0">
                    <p className="text-3xl leading-tight text-gp-violet md:text-4xl lg:text-5xl">
                      <span className="block">Integrative,</span>
                      <span className="block">whole-person care</span>
                    </p>
                    <h1 className="mt-2 text-lg leading-normal md:mt-0 max-w-base text-gp-violet lg:text-2xl">
                      <span className="block">
                        Heal the conditions that matter most to employees
                      </span>
                      {/* <span className="block">
                        Your top conditions in one place
                      </span> */}
                    </h1>
                    <Link
                      to="/partner"
                      className="inline-flex items-start px-6 py-4 mt-6 text-lg font-bold uppercase transition-all duration-300 rounded bg-gp-violet text-gp-off-white lg:text-xl lg:px-8 lg:py-5 lg:min-h-24 hover:no-underline hover:bg-gp-violet-70 focus:no-underline focus:bg-gp-violet-70 md:mt-0"
                    >
                      Request a demo
                    </Link>
                  </div>
                  <div className="flex flex-col items-end">
                    <img
                      src={homepageHero}
                      alt="A woman sitting, looking content."
                      className="w-full max-w-md"
                    />
                  </div>
                </div>
              </div>
            </SectionContainer>
          </div>

          {/* Hero background decoration */}
          <svg
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 866 520"
            className="absolute top-0 right-0 z-0 hidden fill-current md:max-w-lg lg:max-w-2xl xl:max-w-3xl text-gp-honey-30 md:block xxxl:hidden"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M866 0H0a520 520 0 0 0 866 388V0Z"
            />
          </svg>
        </div>

        <MobileContainer highlight={true}>
          <div className="max-w-5xl px-6 mx-auto md:px-0">
            <div className="flex items-end justify-center pt-6 md:pt-12 lg:pt-20 ">
              <h2 className={h2Violet}>
                Employees get significantly better health outcomes
              </h2>
            </div>
            <div className="relative flex flex-col items-start mx-auto space-y-6 md:pt-8 md:px-6 lg:max-w-4xl md:items-center lg:pr-0 lg:pl-10">
              <svg
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 129 342"
                className="absolute left-0 hidden -ml-16 bottom-4/12 lg:block max-w-32"
              >
                <path
                  d="M1 0h96H1Zm96 311H49c-27 0-49-22-49-49h2c0 26 21 47 47 47h48v2Zm-48 0c-27 0-49-22-49-49V0h2v262c0 26 21 47 47 47v2ZM97 0v310V0Z"
                  fill="#372C72"
                />
                <circle cx="97" cy="310" r="32" fill="#DB786B" />
              </svg>
              <div className="grid w-full grid-cols-3 gap-6 pb-6 md:border-2 rounded-xxlg sm:gap-12 lg:gap-18 sm:px-8 sm:space-y-0 border-gp-violet md:py-12">
                <Stat
                  statNumber="20"
                  statModifier="%"
                  statLine1="Average annual"
                  statLine2="enrollment"
                  background="grapefruit"
                />

                <Stat
                  statNumber="90"
                  statModifier="%"
                  statLine1="See health"
                  statLine2="improvement"
                  background="honey"
                />

                <Stat
                  statNumber="30"
                  statModifier="%+"
                  statLine1="Reduction in"
                  statLine2="STD claims"
                  background="violet"
                />
              </div>
            </div>
          </div>
        </MobileContainer>

        <MobileContainer>
          <div className="relative px-6 py-6 mx-auto md:max-w-3xl lg:max-w-4xl md:py-0 lg:pl-12 lg:pr-0 md:flex md:space-x-12 md:items-center">
            <p className={`${h2Violet} md:text-right`}>
              It's why these clients and partners joined Goodpath
            </p>

            <Circle className="absolute hidden w-16 h-16 -mt-8 fill-current left-1/5 top-1/2 lg:block text-gp-honey" />

            <PartnerImages border={true} />
          </div>
        </MobileContainer>

        {/* Integrative section */}
        <MobileContainer highlight={true}>
          <div className="relative px-6 pb-8 mx-auto space-y-6 md:max-w-3xl lg:max-w-4xl md:pt-12 md:pb-20 lg:px-10 md:border-r-2 md:border-b-2 border-gp-violet md:mt-0 md:rounded-br-xxlg">
            <Circle className="absolute top-0 right-0 hidden w-16 h-16 -mt-8 -mr-8 fill-current md:block text-gp-violet" />
            <h2 className={`${h2Violet} pb-2`}>
              Integrative care is complete, whole-person medicine
            </h2>
            <div className="flex flex-col items-center space-y-2 md:grid md:space-y-0 md:grid-cols-70-30">
              <img
                src={integrativeGifMobile}
                alt="An animation showing the many parts of integrative care: exercise therapy, cognitive therapy, mind-body techniques, ergonomics, and nutrition counseling."
                className="md:hidden"
              />
              <img
                src={integrativeGifDesktop}
                alt="An animation showing the many parts of integrative care: exercise therapy, cognitive therapy, mind-body techniques, ergonomics, and nutrition counseling."
                className="hidden pr-8 md:block"
              />
              <div>
                <p className={paragraphStyle}>
                  It addresses the symptom and the causes, factoring in habits,
                  diet, sleep, stress, and more
                </p>

                <p className={`${paragraphStyle} mt-4`}>
                  And it treats these together, leading to better health
                  outcomes than just one treatment alone
                </p>
              </div>
            </div>
          </div>
        </MobileContainer>

        {/* Condition section */}
        <MobileContainer>
          <div className="relative px-6 pt-2 pb-8 mx-auto space-y-6 md:pt-0 md:max-w-3xl lg:max-w-4xl lg:px-10 md:border-l-2 md:border-b-2 border-gp-violet md:rounded-bl-xxlg md:py-12">
            <Circle className="absolute top-0 left-0 hidden w-16 h-16 -ml-8 fill-current -mt-14 md:block text-gp-slate" />
            <h2 className="pb-8 text-2xl leading-tight text-center md:text-left lg:text-3xl text-gp-violet">
              A single solution for all your top conditions
            </h2>
            <ConditionList />
          </div>
        </MobileContainer>

        {/* Video */}
        <div
          id="video"
          className="relative z-10 mx-auto mt-12 md:max-w-3xl lg:max-w-4xl md:mt-0"
        >
          <SectionContainer size="" border="border-right" rounded="">
            <div className="relative md:p-12 md:pb-24 md:bg-gp-white">
              <Circle className="absolute top-0 right-0 hidden w-16 h-16 -mt-8 -mr-8 fill-current md:block text-gp-honey" />
              <Circle className="absolute bottom-0 right-0 hidden w-16 h-16 -mt-8 -mr-8 fill-current md:block text-gp-violet" />
              <h2
                className={`${h2Violet} mx-auto md:ml-0 pb-4 pt-2 md:py-0 bg-white max-w-xl md:text-left mb-5 px-4`}
              >
                Goodpath took something complicated and made it simple
              </h2>
              <EmbedVideo videoId="3fntyxsd8l" autoplay={false} />
            </div>
          </SectionContainer>
        </div>

        {/* Full width testimonial */}
        <div className="relative z-10 mt-8 md:mt-0">
          <TestimonialSlider />
        </div>

        {/* Learn more */}
        <MobileContainer>
          <div className="px-8 py-8 mx-auto lg:py-16 xl:py-16 md:max-w-3xl lg:max-w-6xl md:border-2 md:border-gp-violet rounded-xxlg">
            <h2 className={h2Violet}>Recent press and publications</h2>

            <div className="grid gap-8 mt-6 md:gap-16 md:grid-cols-2 md:mt-12">
              <div className="flex flex-col md:justify-center">
                <a
                  href="https://time.com/6160010/long-covid-patients-back-to-work/"
                  target="_blank"
                  rel="noreferrer"
                  className="transition-all duration-300 ease-in-out hover:opacity-75"
                >
                  <img
                    src="https://api.time.com/wp-content/uploads/2022/03/return-to-office.jpg"
                    className="object-cover object-bottom h-64"
                    alt="People walking through a building security checkpoint symbolizing employees returning to work."
                  />
                  <div className="flex flex-grow flex-shrink-0 mt-4 md:flex-grow-0">
                    <img
                      src={Time}
                      alt="Time magazine logo."
                      className="h-12"
                    />
                  </div>
                  <h3 className={articleHeadline}>
                    Back-to-Office Pressure Is Creating a Crisis for Long COVID
                    Patients
                  </h3>
                  <p className={articleDate}>April 5, 2022</p>
                </a>
              </div>
              <div className="flex flex-col space-y-8 lg:space-y-2 md:justify-around">
                {secondaryArticles.map((article) => (
                  <div key={article.headline} className="flex flex-col">
                    <a
                      href={article.link}
                      target="_blank"
                      rel="noreferrer"
                      className="transition-all duration-300 ease-in-out hover:opacity-75"
                    >
                      <div className="flex flex-grow flex-shrink-0 lg:flex-grow-0">
                        <img
                          src={article.logo}
                          alt={article.logoAlt}
                          className="h-12"
                        />
                      </div>
                      <h3 className={articleHeadline}>{article.headline}</h3>
                      <p className={articleDate}>{article.date}</p>
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </MobileContainer>

        {/* CTA */}
        <MobileContainer>
          <div className="flex flex-col items-center justify-center px-8 py-8 mx-auto mt-2 space-y-2 lg:mt-8 lg:max-w-sm md:space-y-6 md:mb-8">
            <Link
              to="/partner"
              className="inline-flex items-start px-6 py-4 text-lg font-bold uppercase transition-all duration-300 rounded bg-gp-violet text-gp-off-white lg:text-xl lg:px-8 lg:py-5 lg:min-h-24 hover:no-underline hover:bg-gp-violet-70 focus:no-underline focus:bg-gp-violet-70"
            >
              Request a demo
            </Link>
            <h2 className={h2Violet}>Bring Goodpath to your employees</h2>
          </div>
        </MobileContainer>
        <div className="absolute top-0 z-0 w-1 h-full min-h-full bg-gp-violet left-1/2 md:hidden"></div>
      </main>
    </>
  )
}

export default Homepage

const secondaryArticles = [
  {
    link:
      'https://finance.yahoo.com/news/sun-life-u-goodpath-partner-202000973.html',
    logo: yahooLogo,
    logoAlt: 'Yahoo Finance logo.',
    headline:
      'Sun Life U.S. and Goodpath partner to improve treatment for musculoskeletal conditions and long COVID-19',
    date: 'March 16, 2023'
  },
  {
    link:
      'https://www.bloomberg.com/press-releases/2022-03-10/yamaha-motor-adds-goodpath-wellness-benefit-to-underscore-its-persistence-innovation-and-vision-in-employee-management',
    logo: Bloomberg,
    logoAlt: 'Bloomberg logo.',
    headline:
      'Yamaha Motor adds Goodpath Wellness Benefit to Underscore its Persistence, Innovation, and Vision in Employee Management',
    date: 'March 10, 2022'
  }
]
