import React from 'react'

export default function Stat({ ...props }) {
  // Stats
  const statCircle =
    'flex items-center justify-center w-12 h-12 md:w-32 md:h-32 text-gp-violet z-10'
  const statNumber = 'text-4xl font-bold md:text-7xl'
  const statPercentage = 'mt-6 text-3xl font-normal md:text-6xl'
  const statDescription =
    'text-sm leading-snug text-center md:text-base lg:text-lg text-gp-grey-90 z-10'

  const grapefruitClass =
    'bg-gp-grapefruit-10 w-16 h-16 md:w-36 md:h-36 rounded-full absolute bottom-auto md:bottom-minus10 right-0 z-0'
  const honeyClass =
    'bg-gp-honey-10 w-14 h-14 md:w-26 md:h-26 rounded-full absolute bottom-4 md:bottom-minus10 z-0'
  const violetClass =
    'bg-gp-violet-5 w-20 h-20 md:w-36 md:h-36 rounded-full absolute top-minus10 md:top-auto right-5 z-0'

  let bgClass
  switch (props.background) {
    case 'grapefruit':
      bgClass = grapefruitClass
      break
    case 'honey':
      bgClass = honeyClass
      break
    case 'violet':
      bgClass = violetClass
      break
    default:
      // no background
      break
  }
  return (
    <>
      <div className="flex flex-col items-center space-y-2 md:space-y-0 relative">
        <div className={statCircle}>
          <div className="-mt-1 leading-none md:-mt-3 z-10">
            <span className={statNumber}>{props.statNumber}</span>
            <span className={statPercentage}>{props.statModifier}</span>
          </div>
        </div>
        <p className={statDescription}>
          <span className="block">{props.statLine1}</span>
          <span className="block">{props.statLine2}</span>
        </p>
        <div className={bgClass} />
      </div>
    </>
  )
}
